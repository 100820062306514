/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

import React from 'react';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import ForumIcon from '@mui/icons-material/Forum';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import LinkIcon from '@mui/icons-material/Link';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PlaceIcon from '@mui/icons-material/Place';


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Images
import bgImage from "assets/images/mtcg-bg.jpeg";
import tthtran from "assets/images/tthtran.png";

import { API } from 'aws-amplify';

class Presentation extends React.Component {
  constructor() {
    super();
    this.state = {
      navRoutes: [],
      userGroupMembers: "",
      currentSession: {
        name: "",
        overview: "",
        date: "",
        time: "",
        agenda: [],
        registerLink: "",
        location: "",
      },
      pastSessions: [],
      futureSessions: [],
      showContact: false,
    };
  }

  loadDashboardData() {
    fetch("https://9e62xn69rd.execute-api.ap-southeast-1.amazonaws.com/dev/dashboardData")
      .then(res => res.json())
      .then(response => {
        let data = response[0];
        let navRoutes = JSON.parse(data.navLinks);

        navRoutes.map(route => {
          route.icon = <Icon>link</Icon>;
          return route;
        })

        navRoutes.push({
          name: "Danh mục",
          icon: <Icon>view_day</Icon>,
          collapse: JSON.parse(data.navMenu),
        });

        this.setState({
          userGroupMembers: data.userGroupMembers,
          navRoutes: navRoutes,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  loadSessions() {
    const apiName = 'mtcgSession';
    const path = '/mtcgSession';
    const init = {
      response: true, 
    };
  
    API
      .get(apiName, path, init)
      .then(response => {
        let pastSessions = [];
        let futureSessions = [];

        let sessions = response.data.map(session => {
          session.agenda = session.agenda ? JSON.parse(session.agenda) : null;
          return session;
        })

        sessions.forEach(session => {
          if (session.future) 
            futureSessions.push(session);
          else
            pastSessions.push(session);
        })

        this.setState({
          currentSession: pastSessions[0],
          pastSessions: pastSessions.slice(1),
          futureSessions: futureSessions,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadDashboardData();
    this.loadSessions();
  }

  render() {
    return (
      <>
        <DefaultNavbar
          routes={this.state.navRoutes}
          sticky
        />
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="dark"
                className="text-center"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Meet The Cloud Guru Series{" "}
              </MKTypography>
              <MKTypography
                variant="body1"
                color="dark"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Chuỗi hội thảo AWS về các chủ đề được quan tâm nhiều tại Việt Nam
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {/* <Posts /> */}

          <MKBox component="section" py={2}>
            <Container>
              <Grid container>
                <Grid item xs={12} lg={9} className="session-main-info">
                  {/* <MKTypography variant="h2" mb={3}>
                    Phiên tiếp theo
                  </MKTypography> */}
                  <MKTypography variant="h3" mb={3}>
                    {this.state.currentSession.name}
                  </MKTypography>
                  <MKTypography variant="body2" mb={6}>
                    {this.state.currentSession.overview}
                  </MKTypography>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <Grid item xs={12} className="session-detail">
                    <MKTypography variant="h4">
                      Chi tiết sự kiện 
                      <MKTypography variant="body2" className="session-detail-child">
                        <EventIcon/> {this.state.currentSession.date}<br/>
                        <ScheduleIcon/> {this.state.currentSession.time}<br/>
                        <PlaceIcon/> {this.state.currentSession.location}
                      </MKTypography>
                      {(!!this.state.currentSession.registerLink) ? 
                        <button className="btn-register" onClick={() => {window.open(this.state.currentSession.registerLink, '_blank').focus();}} >
                          Đăng ký tham gia
                        </button> : 
                        <button className="btn-register" disabled>
                          Chưa mở đăng ký
                        </button>}
                    </MKTypography>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} className="session-main-info">
                  <MKTypography variant="h4" mb={6}>
                    Liên kết
                    <MKTypography variant="body2" mt={1}>
                      <a href="https://mtcg.awslibvn.com/ee" target="_blank" className="session-link">
                          <Icon>link</Icon> Hướng dẫn truy cập tài khoản làm lab trên Event Engine
                      </a>
                    </MKTypography>
                  </MKTypography>

                  <MKTypography variant="h4" mb={1}>
                    Nội dung
                  </MKTypography>
                  {!this.state.currentSession.agenda ? 
                    <MKTypography variant="body2">Chưa công bố.</MKTypography> :
                    this.state.currentSession.agenda.map(agendaItem => <Grid key={agendaItem.id} container className="agenda-item">
                    <Grid item xs={12} lg={9}>
                    <MKTypography variant="body2">
                      <span className="agenda-item-name">{agendaItem.name}</span> <br/>
                      {agendaItem.speaker ? <span className="agenda-item-speaker">- {agendaItem.speaker}</span> : ""}
                    </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={3} className="agenda-action">
                      {agendaItem.link.map(link =>
                        <button className="btn-agenda-item-action" onClick={() => {window.open(link, '_blank').focus();}}>
                          {agendaItem.type === "presentation" ? 
                            <span><DownloadIcon/> Tải slide</span> :
                            <span><LinkIcon/> Hướng dẫn</span>}
                        </button>)}
                    </Grid>
                  </Grid>)}
                </Grid>
              </Grid>
            </Container>
          </MKBox>

          <MKBox component="section" py={2} mt={3}>
            <Container>
              <Grid container item xs={12} lg={6}>
                <MKTypography variant="h4" mb={1}>
                  Các phiên sắp tới
                </MKTypography>
              </Grid>
              {this.state.futureSessions.map((session, index) => {
                let stateName="open-future-session" + index;
                return <Grid container item xs={12}>
                  <Card className="prev-session">
                    <CardHeader className="prev-session-name"
                      title={session.name}
                      action={
                        <IconButton
                          onClick={() => this.setState((prevState) => {
                            prevState[stateName] = !prevState[stateName];
                            return prevState;
                          })}
                          aria-label="expand"
                          size="small"
                        >
                          {this.state[stateName] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      }
                    >
                    </CardHeader>
                    <div style={{ backgroundColor: 'rgba(211,211,211,0.4)'}}>
                    <Collapse in={this.state[stateName]} timeout="auto" unmountOnExit>
                      <CardContent>
                        <div className='prev-session-detail'><EventIcon/> {session.date}</div> 
                        <div className='prev-session-detail'><ScheduleIcon/> {session.time}</div> 
                        <div className='prev-session-detail'><PlaceIcon/> {session.location}</div> 
                        <MKTypography variant="body2" mt={1}>
                          {session.overview}
                        </MKTypography>
                        <MKTypography variant="h6" mt={1}>
                          Nội dung
                        </MKTypography>

                        {!session.agenda ? 
                          <MKTypography variant="body2">Chưa công bố.</MKTypography> :
                          session.agenda.map(agendaItem => <Grid key={agendaItem.id} container className="agenda-item">
                          <Grid item xs={12} lg={9}>
                          <MKTypography variant="body2">
                            <span className="agenda-item-name">{agendaItem.name}</span> <br/>
                            {agendaItem.speaker ? <span className="agenda-item-speaker">- {agendaItem.speaker}</span> : ""}
                          </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={3} className="agenda-action">
                            {agendaItem.link.map(link =>
                              <button className="btn-agenda-item-action" onClick={() => {window.open(link, '_blank').focus();}}>
                                {agendaItem.type === "presentation" ? 
                                  <span><DownloadIcon/> Tải slide</span> :
                                  <span><LinkIcon/> Hướng dẫn</span>}
                              </button>)}
                          </Grid>
                        </Grid>)}
                      </CardContent>
                    </Collapse>
                    </div>
                  </Card>
                </Grid>})}
            </Container>
          </MKBox>

          <MKBox component="section" py={2}>
            <Container>
              <Grid container item xs={12} lg={6}>
                <MKTypography variant="h4" mb={1}>
                  Các phiên đã kết thúc
                </MKTypography>
              </Grid>
              {this.state.pastSessions.map((session, index) => {
                let stateName="open-prev-session" + index;
                return <Grid container item xs={12}>
                  <Card className="prev-session">
                    <CardHeader className="prev-session-name"
                      title={session.name}
                      action={
                        <IconButton
                          onClick={() => this.setState((prevState) => {
                            prevState[stateName] = !prevState[stateName];
                            return prevState;
                          })}
                          aria-label="expand"
                          size="small"
                        >
                          {this.state[stateName] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      }
                    >
                    </CardHeader>
                    <div style={{ backgroundColor: 'rgba(211,211,211,0.4)'}}>
                    <Collapse in={this.state[stateName]} timeout="auto" unmountOnExit>
                      <CardContent>
                        <div className='prev-session-detail'><EventIcon/> {session.date}</div> 
                        <div className='prev-session-detail'><ScheduleIcon/> {session.time}</div> 
                        <div className='prev-session-detail'><PlaceIcon/> {session.location}</div> 
                        <MKTypography variant="body2" mt={1}>
                          {session.overview}
                        </MKTypography>
                        <MKTypography variant="h6" mt={1}>
                          Nội dung
                        </MKTypography>

                        {!session.agenda ? 
                          <MKTypography variant="body2">Chưa công bố.</MKTypography> :
                          session.agenda.map(agendaItem => <Grid key={agendaItem.id} container className="agenda-item">
                          <Grid item xs={12} lg={9}>
                          <MKTypography variant="body2">
                            <span className="agenda-item-name">{agendaItem.name}</span> <br/>
                            {agendaItem.speaker ? <span className="agenda-item-speaker">- {agendaItem.speaker}</span> : ""}
                          </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={3} className="agenda-action">
                            {agendaItem.link.map(link =>
                              <button className="btn-agenda-item-action" onClick={() => {window.open(link, '_blank').focus();}}>
                                {agendaItem.type === "presentation" ? 
                                  <span><DownloadIcon/> Tải slide</span> :
                                  <span><LinkIcon/> Hướng dẫn</span>}
                              </button>)}
                          </Grid>
                        </Grid>)}
                      </CardContent>
                    </Collapse>
                    </div>
                  </Card>
                </Grid>})}
            </Container>
          </MKBox>

          <Container sx={{ mt: 6 }}>
            <BuiltByDevelopers />
          </Container>

          <MKBox pt={6} pb={6}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                    Tham gia AWS Study Group
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Cộng đồng {this.state.userGroupMembers} người dùng AWS tại Việt Nam
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  my={{ xs: 5, lg: "auto" }}
                  mr={{ xs: 0, lg: "auto" }}
                  sx={{ textAlign: { xs: "center", lg: "right" } }}
                >
                  <MKSocialButton
                    component="a"
                    href="https://www.facebook.com/groups/660548818043427"
                    target="_blank"
                    color="facebook"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-facebook" />
                    &nbsp;Tham gia
                  </MKSocialButton>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox px={1}>
          <SimpleFooter />
        </MKBox>

        <a href="#" className="float tooltip" onClick={() => {
          this.setState({showContact: !this.state.showContact});
        }}>
          {!this.state.showContact ? <ForumIcon className="my-float"/> : <CloseIcon className="my-float"/>}
          <span className="tooltiptext">Liên hệ</span>
        </a>
        {this.state.showContact ? <MKBox px={1} className="contact">
          <Grid item xs={12}>
            <MKBox>
              <Card>
                <Grid container>
                  <Grid item xs={6} md={4} lg={4} sx={{ mt: -6 }}>
                    <MKBox width="100%" pt={2} pb={1} px={2}>
                      <MKBox
                        component="img"
                        src={tthtran}
                        alt="Hằng Trần"
                        width="100%"
                        borderRadius="md"
                        shadow="lg"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
                    <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                      <MKTypography variant="h5">Hằng Trần</MKTypography>
                      <MKTypography variant="h6" className="color-aws" mb={1}>
                        Account Manager
                      </MKTypography>
                      <MKTypography variant="body2" color="text">
                        Nếu bạn cần hỗ trợ trong quá trình thử nghiệm và sử dụng AWS, vui lòng liên hệ: <br/>
                        <span className='color-aws'><span className='mail-icon'><MailOutlineIcon/></span> tthtran@amazon.com</span>
                      </MKTypography>
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </MKBox>
          </Grid>
        </MKBox> : <></>}
      </>
    );
  }
}

export default Presentation;

/* eslint-disable */