/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

import iconSlack from "assets/images/logo-slack.png";
import qrSlack from "assets/images/slack-qr.png";

function BuiltByDevelopers() {
  const bgImage =
    // "assets/images/mtcg-bg.jpeg";
    "https://d1.awsstatic.com/Digital%20Marketing/House/Hero/products/Quantum/Site-Merch_AWS-Braket_Hero-BG.9638b9c6005c3fe74613b946c15c0df7b9e98e75.png";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.2),
            rgba(gradients.dark.state, 0.2)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container>
          <Grid container item xs={12} lg={8} sx={{ pl: { xs: 0, lg: 6 } }}>
            <MKTypography variant="h4" color="white" fontWeight="bold">
              Cộng đồng <img className="logo-slack" src={iconSlack} alt="slack" />
            </MKTypography>
            <MKTypography variant="h1" color="white" mb={1} className="slack-group-name">
              Meet The Cloud Guru
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              Cập nhật thông tin về các sự kiện, dịch vụ, tính năng, hay trao đổi về các vấn đề trong quá trình sử dụng AWS.
            </MKTypography>
            {/* <MKTypography
              component="a"
              href="https://mtcg.awslibvn.com/slack"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Tham gia <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography> */}
          </Grid>
          <Grid container item xs={12} lg={4} className="qr-slack-container">
              <Grid xs={12}>
                <button className="btn-join-slack" onClick={() => {window.open("https://mtcg.awslibvn.com/slack", '_blank').focus();}}>
                  <span>Tham gia</span>
                </button>
              </Grid>
              <Grid className="txt-join-slack-label" xs={12}>
                - Hoặc quét mã QR -
              </Grid>
              <Grid xs={12}>
                <img className="qr-slack" src={qrSlack} alt="slack"/>
              </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
/* eslint-disable */
